<template>
  <div class="section-subtitle">
    <h3>{{ title }}</h3>
  </div>
</template>
<script>
export default {
  name: "SectionSubTitle",
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
.section-subtitle {
  margin: 0 0 $section-padding 0;

  @include set-headings($font-size-level-6, $font-weight-bolder);
}
</style>
