<template>
  <PrimaryMenu />

  <Header title="Járműveink" />

  <SectionBlock>
    <SectionTitle :title="`${car.title} (${car.color})`" />
    <SectionContent>
      <BRow gutterX="4" gutterY="4">
        <BCol sm="6">
          <BImg class="car-base-image" :src="require(`@/assets/cars/${car.image}`)" :alt="car.title" rounded />
        </BCol>
        <BCol sm="6">
          <div class="car-base-description" v-html="car.description" />
        </BCol>
        <BCol sm="6">
          <div class="car-specifications">
            <SectionSubTitle title="Felszereltség" class="my-4" />
            <ul>
              <li v-for="(key, index) in filterEmptyValues(car.equipments)" :key="`${key}-${index}`">
                {{ renderEquipmentName(key) }}
              </li>
            </ul>
          </div>
        </BCol>
        <BCol sm="6">
          <div class="car-specifications">
            <SectionSubTitle title="Műszaki adatok" class="my-4" />
            <ul>
              <li v-for="(value, key, index) in filterEmptyValues(car.specifications)" :key="`${key}-${index}`">
                {{ renderSpecificationName(key) }}: {{ renderSpecificationValue(value) }}
                {{ renderSpecificationUnit(key) }}
              </li>
            </ul>
          </div>
        </BCol>
      </BRow>
    </SectionContent>
  </SectionBlock>

  <SectionServices class="bg-light" />

  <!--  <SectionGallery-->
  <!--    section-title="Néhány kép azokról, akik ezt az autót választották..."-->
  <!--    :filterGalleryItemsByCategory="filterGalleryItemsByCategory"-->
  <!--    :key="car.id"-->
  <!--  />-->

  <SectionCars />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionSubTitle from "@/components/layout/sections/parts/SectionSubTitle";
import SectionCars from "@/components/layout/sections/SectionCars";
import SectionServices from "@/components/layout/sections/SectionServices";
// import SectionGallery from "@/components/layout/sections/SectionGallery";
const cleanDeep = require("clean-deep");

export default {
  name: "Car",
  title: "Járműveink",
  components: {
    // SectionGallery,
    SectionServices,
    SectionCars,
    SectionSubTitle,
    SectionContent,
    SectionTitle,
    SectionBlock,
    PrimaryMenu,
    Header,
    SectionContact,
    Footer,
  },
  data() {
    return {
      car: null,
    };
  },

  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.car = this.fetchCar();
      }
    );

    this.car = this.fetchCar();
  },

  methods: {
    fetchCar() {
      let result = null;

      this.cars.map((car) => {
        if (car.slug === this.$route.params.slug) {
          result = car;
        }
      });

      // if (!result) {
      //   this.goTo("Cars");
      // }

      return result;
    },

    filterEmptyValues(specifications) {
      return cleanDeep(specifications);
    },

    renderEquipmentName(equipmentKey) {
      return this.carEquipmentsNames[equipmentKey];
    },

    renderSpecificationName(specificationKey) {
      return this.carSpecificationsNames[specificationKey];
    },

    renderSpecificationValue(specificationValue) {
      if (typeof specificationValue === "object") {
        return `${specificationValue.min || ""} - ${specificationValue.max || ""}`;
      } else {
        return specificationValue;
      }
    },

    renderSpecificationUnit(specificationKey) {
      return this.carSpecificationsUnits[specificationKey];
    },
  },
};
</script>

<style scoped lang="scss"></style>
